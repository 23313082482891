import requestAPI from '../requestBeApi.js'

const ordersAPI = {
  getOrders() {
    const url = `/v1/merchants/orders/`

    return requestAPI({
      method: 'GET',
      url,
    })
  },

  updateOrder(payload) {
    const url = `/v1/merchants/orders/update/`

    return requestAPI({
      method: 'PATCH',
      url,
      payload,
    })
  },
}

export default ordersAPI
