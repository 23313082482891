<template>
  <div class="admin-container">
    <tabs :activeTab="'activeOrders'" />

    <div class="merchant-active-orders" v-loading="requestPending">
      <h1>Active orders</h1>

      <p>
        In case of questions ask the RR founder via email
        ikastner@runningtheroad.ca or phone 6473945101
      </p>

      <el-input
        placeholder="Search for client / phone"
        prefix-icon="el-icon-search"
        v-model="searchPhrase"
      >
      </el-input>
      <br /><br />

      <ol class="merchant-active-orders__list">
        <li
          class="merchant-active-orders__list-item"
          v-for="order in filteredOrders"
          :key="order.id"
        >
          <div class="list-item__basic">
            <span>
              Client: <strong>{{ order.clientName }}</strong>
              <br />
              Phone: <strong>{{ order.clientPhone }}</strong>
            </span>

            <span>
              Total price: <strong>{{ order.totalPrice }}</strong>
            </span>

            <el-button
              type="secondary"
              icon="el-icon-view"
              @click="order.showDetails = !order.showDetails"
              size="mini"
              >See details</el-button
            >
          </div>

          <div
            class="list-item__details"
            :class="{ 'list-item__details--show': order.showDetails }"
          >
            <h3>Ordered products:</h3>
            <br />

            <table>
              <tr>
                <th>Name</th>
                <th>Store</th>
                <th>Quantity</th>
                <th>Delivery address</th>
                <th>Fulfill by</th>
                <th>Details</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
              <tr v-for="item in order.items" :key="item.id">
                <td>{{ item.productName }}</td>
                <td>{{ item.store }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.deliveryAddress }}</td>
                <td>{{ getFormattedDate(item.fulfillBy) }}</td>
                <td>{{ item.details }}</td>
                <td>{{ item.price }}</td>
                <td>
                  <el-select
                    v-model="item.status"
                    @change="
                      (value) => {
                        updateOrderStatus(item.id, value)
                      }
                    "
                  >
                    <el-option label="ordered" value="O"></el-option>
                    <el-option label="collected" value="C"></el-option>
                    <el-option label="ready for pickup" value="RFP"></el-option>
                    <el-option label="received" value="R"></el-option>
                  </el-select>
                </td>
              </tr>
            </table>
            <br />
          </div>
        </li>
      </ol>

      <el-alert
        v-if="unexpectedError"
        title="Unexpected error while loading orders"
        type="error"
        show-icon
        @close="unexpectedError = false"
      >
      </el-alert>

      <el-alert
        v-if="orderUpdateError"
        title="Unexpected error while updating order status"
        type="error"
        show-icon
        @close="orderUpdateError = false"
      >
      </el-alert>
    </div>
  </div>
</template>

<script>
  import Tabs from '../../components/MerchantAdmin/Tabs.vue'
  import ordersAPI from '@/utils/api/ordersAPI.js'

  export default {
    name: 'merchant-active-orders',

    data() {
      return {
        searchPhrase: '',
        orders: [],
        requestPending: false,
        unexpectedError: false,
        orderUpdateError: false,
      }
    },

    computed: {
      filteredOrders() {
        return this.orders.filter((order) => {
          return (
            order.clientName
              ?.toLowerCase()
              .includes(this.searchPhrase.toLowerCase()) ||
            order.clientPhone
              ?.toLowerCase()
              .includes(this.searchPhrase.toLowerCase())
          )
        })
      },
    },

    components: {
      Tabs,
    },

    created() {
      this.loadOrders()
    },

    methods: {
      async loadOrders() {
        this.requestPending = true

        const resp = await ordersAPI.getOrders()

        if (resp.ok) {
          const data = await resp.json()
          const orders = data.map((order) => {
            order.showDetails = false
            return order
          })
          this.orders = orders
        } else {
          this.unexpectedError = true
        }

        this.requestPending = false
      },

      async updateOrderStatus(orderId, newStatus) {
        this.requestPending = true

        const payload = {
          id: orderId,
          status: newStatus,
        }

        const resp = await ordersAPI.updateOrder(payload)

        if (resp.ok) {
          this.$message({
            message: 'Your order has been updated',
            type: 'success',
          })
        } else {
          this.orderUpdateError = true
        }

        this.requestPending = false
      },

      prependZeroToTime(time) {
        time = time + ''

        if (time.length === 1) {
          time = '0' + time
        }
        return time
      },

      getFormattedDate(rawDate) {
        const dateObj = new Date(rawDate)
        const date =
          dateObj.getDate() +
          '/' +
          (dateObj.getMonth() + 1) +
          '/' +
          dateObj.getFullYear()
        const time =
          this.prependZeroToTime(dateObj.getHours()) +
          ':' +
          this.prependZeroToTime(dateObj.getMinutes())
        return date + ' ' + time
      },
    },
  }
</script>

<style lang="scss">
  .merchant-active-orders {
    .merchant-active-orders__list {
      padding: 0;
      list-style: none;
    }

    .merchant-active-orders__list-item {
      border-bottom: 1px solid #dcdfe6;
      padding: 10px 0;
      font-size: 14px;
      cursor: pointer;

      .list-item__basic {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .list-item__details {
        display: none;
        padding-top: 20px;

        &.list-item__details--show {
          display: block;
        }

        table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }

        td,
        th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
        }

        tr:nth-child(even) {
          background-color: #fafafa;
        }
      }
    }
  }
</style>
