<template>
  <div class="merchant-tabs">
    <el-tabs v-model="activeTab" @tab-click="routeTo">
      <el-tab-pane label="Add Store" name="addStore">Add Store</el-tab-pane>
      <el-tab-pane label="Active Orders" name="activeOrders"
        >Active Orders</el-tab-pane
      >
      <el-tab-pane label="Logout" name="logout">Logout</el-tab-pane>
    </el-tabs>
    <br />
    <br />
  </div>
</template>

<script>
  import { router } from '../../main.js'

  export default {
    name: 'merchant-tabs',

    props: {
      activeTab: {
        required: true,
        type: String,
      },
    },

    methods: {
      routeTo(tab) {
        switch (tab.name) {
          case 'addStore':
            router.push('/merchantadminpanel-add-store')
            break
          case 'activeOrders':
            router.push('/merchantadminpanel-active-orders')
            break
          case 'logout':
            router.push('/merchantadminpanel-logout')
            break
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .merchant-tabs {
    margin-top: 20px;

    .el-tab-pane {
      display: none;
    }
  }
</style>
