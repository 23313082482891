import config from '@/config.js'
import { router } from '@/main.js'
import auth from './auth'

const requestBeAPI = async (data) => {
  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }

  const response = await fetch(config.BE_URL + data.url, {
    method: data.method,
    headers,
    body: JSON.stringify(data.payload),
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
    return error
  })

  if (response.status === 401) {
    if (router.currentRoute.path !== '/merchantadminpanel-login') auth.logOut()
  }

  return response
}

export default requestBeAPI
